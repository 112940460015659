const dashboard = [
  {
    path: '/event/:id',
    name: 'event-dashboard',
    component: () => import('@/views/EventViews/EventDashboard/EventDashboard.vue'),
    meta: {
      layout: 'content',
    },

    redirect: {
      name: 'home-page',
    },
    children: [
      {
        path: 'statistics',
        name: 'home-page',
        component: () => import('@/views/EventViews/EventDashboard/StatisticsPage.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'guest/list',
        name: 'apps-user-list',
        component: () => import('@/views/EventViews/GuestManagement/InviteList.vue'),
        props: true,
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'guest/edit/:guestID',
        name: 'apps-user-edit',
        component: () => import('@/views/EventViews/GuestManagement/EditGuestDetails.vue'),
        props: true,
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'guest/groups',
        name: 'apps-user-manage-group',
        component: () => import('@/views/EventViews/EventDashboard/Groups/ManageGroups.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'guest/timing',
        name: 'apps-user-manage-timing',
        component: () => import('@/views/EventViews/EventDashboard/Timing.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'settings',
        name: 'apps-user-event-settings',
        component: () => import('@/views/EventViews/EventDashboard/EventSettings.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'message-template',
        name: 'apps-user-message-template',
        component: () => import('@/views/EventViews/EventDashboard/NotificationTemplates/MessageTemplates.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'call-messages',
        name: 'apps-user-call-messages',
        component: () => import('@/views/EventViews/EventDashboard/NotificationTemplates/CallTemplates.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'invitation',
        name: 'invitation',
        component: () => import('@/views/EventViews/EventDashboard/InvitationPreview.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: 'navigate',
        name: 'preview-navigation',
        component: () => import('@/views/GuestViews/Navigation.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: 'my-expenses',
        name: 'event-expenses',
        component: () => import('@/views/UserViews/EventExpenses.vue'),
        meta: {
          layout: 'content',
        },
      },
    ],
  },
]

export default dashboard
