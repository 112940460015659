export default {
  MyEvents: 'האירועים שלי',
  MyProfile: 'הפרופיל שלי',
  ContactUS: 'צור קשר',
  CallTemplate: 'תבנית שיחות לאורחים',
  NewEvent: 'יצירת אירוע חדש',
  NewGroup: 'יצירת קבוצה חדשה',
  GroupName: 'שם הקבוצה',
  EditGroupName: 'עריכת שם הקבוצה',
  NumberGroupMember: 'חברי קבוצה',
  Edit: 'עריכה',
  EditingInvitees: 'עריכת מוזמנים',
  WhoAttending: 'מי מגיע?',
  AttendingPerInvitee: 'יש להזין לכל רשומה את מספר המגיעים',
  Delete: 'מחיקה',
  Saving: 'שמירה',
  DeleteLang: 'מחיקת שפה',
  AddNewInvitee: 'הוספת מוזמן',
  GuestDetails: 'פרטי המוזמן',
  GuestAnswer: 'תשובות המוזמן',
  fullName: 'שם מלא',
  phoneNumber: 'מספר טלפון',
  countOfInvites: 'מספר מוזמנים',
  NumberGuestAnswer: 'כמות מגיעים',
  outOf: 'מתוך',
  Progress: 'התקדמות',
  Export: 'יצוא לאקסל',
  GeneralSettings: 'הגדרות בסיסיות',
  eventSettings: 'פרטי אירוע',
  AdvancedSettings: 'הגדרות מתקדמות',
  Personalize: 'התאמה אישית',
  Terms: 'תנאי השימוש',
  PrivacyTerms: 'מדיניות הפרטיות',
  EventName: 'שם האירוע',
  EventNamePerLang: 'שם האירוע ב',
  ExampleEventName: 'החתונה של חיים ועדי',
  EventLocationName: 'שם מיקום האירוע (לדף הזמנה)',
  EventLocationGoogle: 'בחרו מיקום אירוע עבור קישור לוויז',
  EventDate: 'תאריך האירוע',
  Date: 'תאריך',
  EventTime: 'שעת האירוע',
  Add: 'הוספה',
  Cancel: 'ביטול',
  Ok: 'אישור',
  Send: 'שליחה',
  Close: 'סגור',
  UploadInvitation: 'העלאת הזמנה',
  InvitationPreview: 'הזמנה',
  ContactInvitees: 'יצירת קשר עם המוזמנים',
  DefaultLanguage: 'שפת ברירת המחדל',
  EventLanguages: 'שפות האירוע',
  EditInvitation: 'עריכת הזמנה',
  Language: 'שפה',
  MainLanguage: 'עברית',
  hebrew: 'עברית',
  english: 'אנגלית',
  russian: 'רוסית',
  arabic: 'ערבית',
  he: 'עברית',
  en: 'אנגלית',
  ru: 'רוסית',
  ar: 'ערבית',
  work_days: 'ראשון-חמישי',
  all_days: 'כל ימות השבוע',
  specialMeals: 'מנות מיוחדות',
  ChooseSpecialMeals: 'בחרו מנות מיוחדות',
  no_special_dish: 'אין צורך לשאול על מנות מיוחדות',
  glatt: 'גלאט',
  vegetarian: 'צמחוני',
  gluten_free: 'ללא גלוטן',
  vegan: 'טבעוני',
  no_need_to_ask: 'אין צורך לשאול',
  some_guests: 'אשאל חלק מהאורחים',
  all_guests: 'אשאל את כולם',
  Transport: 'הסעה',
  Meal: 'מנה',
  GetPlacesName: 'הקלד/י את שם העיר',
  AddName: 'ENTER - להוספה',
  CreateOne: 'הוספת אפשרות נוספת',
  ClassGroup: 'קבוצה',
  Previous: 'הקודם',
  Next: 'הבא',
  Finish: 'סיום',
  FieldRequired: 'שדה חובה',
  RequireNum: 'שדה זה הוא מספר',
  MaxField: 'מספר התווים המקסימלי הוא {num}',
  MinField: 'מספר התווים המינימלי הוא {num}',
  WelcomeToAnitaLee: 'ברוכים הבאים!',
  Or: 'או',
  FirstEvent: 'אירוע ראשון שלך?',
  Register: 'הרשמה',
  HappyToBePartOfYourEvent: 'שמחים להיות חלק מהאירוע שלך!',
  PleaseSignUp: 'צרו את החשבון שלכם על מנת לנהל את האירוע',
  CreateAccount: 'הרשמה',
  YourEventYourControl: 'האירוע שלך - השליטה שלך! הרשמו כעת',
  IAgree: ' אני מאשר/ת את',
  AlreadyHaveAccount: 'יש לך כבר חשבון?',
  AccountExist: '*המשתמש קיים כבר',
  ConfirmPasswordNotMatch: 'הסיסמאות לא זהות',
  NewPassword: 'סיסמה חדשה',
  Password: 'סיסמה',
  ConfirmPassword: 'אימות סיסמה',
  ChangePassword: 'שינוי סיסמה',
  ForgotPassword: 'שכחת סיסמה?',
  ForgotPasswordMsg: 'יש להזין את כתובת האימייל שאיתה נרשמת, ואנחנו נשלח לך הוראות לאיפוס הסיסמה שלך.',
  PasswordChangeSuccessful: 'הסיסמה שונתה בהצלחה',
  BackToLogin: 'חזרה להתחברות',
  Login: 'התחברות',
  Logout: 'התנתקות',
  PasswordGuide: [
    ' יש לבחור בסיסמה בת 4 עד 20 תווים',
    ' לפחות ספרה אחת (0-9) או תו מיוחד (!،@..) ',
    ' לפחות אות אחת גדולה באנגלית (A-Z)',
    ' לפחות אות אחת קטנה באנגלית (a-z)',
  ],
  WeakPassword: 'סיסמה חלשה',
  NotValidEmail: 'אימייל לא תקין',
  errorNewEvent: 'לא ניתן ליצור את האירוע, נסו שוב מאוחר יותר',
  errorManageEvents: 'משהו השתבש, נסו שוב מאוחר יותר',
  errorNewGroup: 'שם הקבוצה קיים',
  invalid_guests_coming: 'מספר המשיבים גבוה ממספר המוזמנים',
  search: 'חיפוש',
  advancedFilter: 'סינון מתקדם',
  filter: 'סינון',
  status: 'סטטוס',
  delete: 'מחיקה',
  inProgress: 'בתהליך',
  approved: 'מגיע',
  declined: 'לא מגיע',
  Yes: 'כן',
  No: 'לא',
  Navigation: 'ניווט לאירוע',
  AddToCalendar: 'הוספה ליומן',
  wrongNumber: 'טעות במספר',
  pending: 'בהמתנה',
  viewCount: 'צפו בהזמנה',
  changedDecision: 'שינו החלטה',
  ifArriveQA: 'האם תגיעו?',
  AmountPeople: 'כמה תהיו?',
  comments: 'הערות',
  messageBeforeDelete: 'האם את/ה בטוח/ה שברצונך למחוק?',
  oops: 'אופס...',
  cantDeleteGuestsInProcess: 'לא הצלחנו למחוק רשומות שכבר התחלנו עבורם תהליך',
  cantDeletePaidEvent: 'לא ניתן למחוק אירוע ששולם',
  messageBeforeStartProccess: 'האם את/ה בטוח/ה שברצונך להתחיל תהליך עבור',
  messageForNumberOfGroups: 'האירוע שלך מכיל',
  ActiveOrders: 'הזמנות פעילות',
  FinishedOrders: 'הזמנות סגורות',
  Details: 'פרטים',
  Invitees: 'רשומות',
  RecentResponse: 'הגיבו לאחרונה',
  ViewAll: 'ראה הכל',
  ChangedDecision: 'שינו החלטה',
  attendingByGroups: 'סיווג מגיעים לפי קבוצות',
  Dashboards: 'תפריט',
  Dashboard: 'דף האירוע',
  GuestsList: 'רשימת מוזמנים',
  CRM: 'CRM',
  Analytics: 'סטטיסטיקה',
  eCommerce: 'eCommerce',
  Calendar: 'לוח שנה',
  Chat: "צ'אט",
  Email: 'כתובת אימייל',
  incorrectIdentificationDetails: 'פרטי ההזדהות שהוזנו שגויים',
  Groups: 'קבוצות',
  ManageGroups: 'ניהול קבוצות',
  AdvantageGroupsManagement: [
    'סדר וארגון בניהול האירוע',
    'סטטיסטיקה לפי קבוצות',
    'חיפוש מהיר ונוח של אורחים',
    'קבלת החלטות מדוייקות',
  ],
  CallTemplatesExplain: ['הכנו לכם תבניות שיחה אוטומטיות על סמך הפרטים שמסרת לנו', 'באפשרותך לערוך את תבניות השיחה'],
  ListenCall: ' על מנת להאזין לפתיח השיחה, לחצו',
  MessageTemplatesExplain: [
    'הכנו לכם הודעות ברירת מחדל על סמך הפרטים שמסרת לנו.',
    'באפשרותך לערוך את ההודעות בכל השפות.',
  ],
  InvitationPreviewExp: 'לכול אורח תצורף הזמנה מותאמת אישית בתחתית ההודעה.',
  ViewInvitation: 'לצפייה בהזמנה',
  MessageTemplates: 'תבנית הודעות לאורחים',
  SettingEvent: 'הגדרות אירוע',
  MessageTemplate: 'תבנית הודעות',
  CallTemplates: 'תבנית שיחות',
  MyOrders: 'ההזמנות שלי',
  MyAccount: 'החשבון שלי',
  Invoice: 'חשבונית',
  List: 'רשימה',
  Preview: 'Preview',
  language: 'עברית',
  Notifications: 'התראות',
  Today: 'היום',
  Yesterday: 'אתמול',
  basic_package: 'חבילת בסיס',
  premium_package: 'חבילת פרימיום',
  basic_desc: 'הודעות בלבד',
  premium_desc: 'הודעות & שיחות',
  basic_package_include_sms: [
    'ניהול מוזמנים',
    'ניהול הוצאות',
    'הזמנה דיגיטלית',
    'עריכת תוכן הודעות',
    '3 סבבי אישורי הגעה בסמס',
    'הודעת סמס הכוונה ביום האירוע',
    'הודעת סמס תודה יום למחרת',
  ],
  basic_package_include_whatsapp: [
    'ניהול מוזמנים',
    'ניהול הוצאות',
    'הזמנה דיגיטלית',
    'עריכת תוכן הודעות',
    '3 סבבי אישורי הגעה בוואסאפ',
    'הודעת סמס הכוונה ביום האירוע',
    'הודעת סמס תודה יום למחרת',
  ],
  basic_package_not_include: ['עריכת תוכן שיחה', '3 סבבי שיחות אוטומטיות'],
  premium_package_include_sms: [
    'ניהול מזומנים',
    'ניהול הוצאות',
    'הזמנה דיגיטלית',
    'עריכת תוכן הודעות',
    '3 סבבי אישורי הגעה בסמס',
    'הודעת סמס הכוונה ביום האירוע',
    'הודעת סמס תודה יום למחרת',
    'עריכת תוכן שיחה',
    '3 סבבי שיחות אוטומטיות',
  ],
  premium_package_include_whatsapp: [
    'ניהול מזומנים',
    'ניהול הוצאות',
    'הזמנה דיגיטלית',
    'עריכת תוכן הודעות',
    '3 סבבי אישורי הגעה בוואטסאפ',
    'הודעת  סמס הכוונה ביום האירוע',
    'הודעת סמס תודה יום למחרת',
    'עריכת תוכן שיחה',
    '3 סבבי שיחות אוטומטיות',
  ],
  premium_package_not_include: [],
  ActivePackage: 'On',
  Active: 'פעיל',
  NotActive: 'לא פעיל',
  ChooseGuestAmount: 'בחר/י את כמות הרשומות',
  For: 'עבור',
  Total: 'סה"כ',
  TotalPaid: 'סה"כ שולם:',
  ExistingPackageAmount: 'שווי חבילה קיימת: ',
  VerifyEmail: 'אימות כתובת המייל',
  NotReceiveEmailQA: 'לא קיבלת את המייל?',
  Resend: 'שלח שוב',
  AddLang: 'הוספת שפה',
  BuyPackage: 'רכישת חבילה',
  UpgradePackage: 'שדרוג חבילה',
  FullyUsedPackage: 'החבילה נוצלה במלואה',
  CantAddInvitee: 'לא ניתן להוסיף רשומות נוספות',
  GroupsClassificationChart: 'סיווג קבוצות',
  NoData: 'אין נתונים',
  NoHaveInvitees: 'אין מוזמנים',
  noNotifications: 'אין התראות',
  OverView: 'תמונת מצב',
  EventDetails: 'נתוני האירוע',
  EventDetailsMessage: 'מספר המוזמנים באירוע שלך: ',
  invitationMessage: 'אישור הגעה',
  call: 'שיחה אוטומטית',
  NotAnswerMessage: 'טרם אישרתם הגעה',
  DirectionMessage: 'תזכורת והכוונה',
  ThanksMsg: 'תודה',
  limitCountOfInvitesMessage: 'כמות המוזמנים קטנה מכמות המשיבים',
  HappyToSeeYou: 'נשמח לראותכם',
  ResponseSuccess: 'תשובתך התקבלה',
  StartDateRsvp: 'מועד התחלת אישורי הגעה',
  StartDateTip: 'הטיפ שלנו - להתחיל 14 ימים לפני האירוע',
  EventStatus: 'סטטוס הזמנה',
  TypeOfOrderPackage: 'החבילה שנרכשה',
  OurPackages: 'החבילות שלנו',
  ExplainAboutThePackages: 'כל החבילות שלנו כוללות מערכת לניהול מוזמנים נוחה ובהתאמה אישית',
  GuideMessageSelectPackage: 'בחר/י את סוג ההודעות SMS/WhatsApp ואת מספר הרשומות בכדי לצפות במחיר החבילה',
  Management: 'ניהול',
  Settings: 'הגדרות',
  Support: 'תמיכה',
  AttendingNotZero: 'מספר המגיעים לא תקין',
  PhoneNumExist: 'מספר הטלפון כבר קיים',
  importContacts: 'ייבוא אנשי קשר',
  ImportGuests: 'ייבוא מוזמנים',
  submitImportContacts: 'אישור וייבוא',
  UploadExcelFile: 'ייבוא מאקסל',
  RegularExcelTemplate: 'תבנית רגילה',
  IplanExcelTemplate: 'תבנית IPLAN',
  importContactsSettingsDesc: 'נא לבחור הגדרות שיחולו על המוזמנים שנבחרו',
  AskTransportation: 'לשאול על הסעה?',
  AskSpecialMeals: 'לשאול מנות מיוחדות?',
  pleaseWait: 'אנא המתן',
  errorFetchingGoogleContacts: 'לא ניתן לייבא אנשי קשר, נסו שוב מאוחר יותר',
  thanksForChoosingUs: 'איזה כיף שבחרתם בנו!',
  createYourExcitingEvent: 'צרו את האירוע המרגש שלכם ממש כאן',
  WhatsAppMessages: 'הזמנות בוואטסאפ',
  SmsMessages: 'הזמנות בסמס',
  Reminder: 'הודעות תזכורת',
  ThanksMessages: 'הודעות תודה',
  CallMessages: 'שיחות',
  sent: 'נשלחו',
  expensesDescription: 'שם ההוצאה',
  supplierName: 'שם הספק',
  totalPayment: 'סה"כ לתשלום',
  paidAmount: 'כבר שולם',
  leftToPay: 'נשאר לשלם',
  eventExpenses: 'ההוצאות שלי',
  requiredTerms: '*יש לאשר את התקנון',
  InvitationToEvent: 'הזמנה לאירוע',
  invitationUploadOption: 'באפשרותכם להעלות את הזמנתכם לאירוע (לא חייב)',
  page_not_found: 'הדף לא נמצא',
  back_to_home: 'בחזרה לדף הבית',
  sent_to_email: 'שלחנו לכתובת הדואר האלקטרוני שלך: ',
  verification_link: 'קישור לאימות',
  please_verify_email: 'על מנת להמשיך, יש לאמת את כתובת המייל בקישור שנשלח אליכם.',
  invalidPhoneNumber: 'מספר לא תקין',
  PhoneNumberDuplicate: 'כפילות של מספר טלפון',
  maxNumOfGroup: 'מספר הקבוצות המקסימלי הוא 10',
  package_price: 'מחיר החבילה: ',
  ForRsvp: 'לפרטים נוספים ואישור הגעה: ',
  ForDirection: 'להנחיות הגעה לחצו כאן: ',
  invalid_coupon: 'קוד קופון שגוי',
  payment_method_credit_card: 'כרטיס אשראי',
  payment_method_bit: 'תשלום ב-Bit',
  Order: 'הזמנה',
  Confirmation: 'אישור',
  How_pay: 'בחרו את צורת התשלום',
  EnterPromoCode: 'הקלידו קוד קופון',
  Apply: 'החל',
  OrderDetails: 'פרטי הזמנה',
  TotalForPay: 'לתשלום',
  Payment: 'ביצוע תשלום',
  Payment_completed_Successfully: 'התשלום בוצע בהצלחה!',
  Thank_you_for_your_purchase: 'תודה שבחרת בנו! מספר אישור ההזמנה הוא: ',
  Go_To_Event_Page: 'לדף האירוע',
  bad_date: 'תאריך האירוע קרוב מידי',
  wrong_package: 'החבילה שנבחרה אינה קיימת',
  payment_failed: 'התשלום נכשל',
  payment: 'תשלום',
  agree_invitation_start_date: 'אני מאשר/ת את תאריך תחילת אישורי ההגעה: ',
  agree_message_templates: 'אני מאשר/ת את תבנית ההודעות שישלחו לאורחים בשפות הבאות: ',
  agree_call_templates: 'אני מאשר/ת את תבנית השיחות שיתבצעו לאורחים בשפות הבאות: ',
  confirm_all_fields: '* יש לאשר את כל השדות',
  must_choose_from_google_location: 'יש לבחור מיקום האירוע מהרשימה',
  eventDateInPast: 'תאריך האירוע כבר עבר',
  CouponDiscount: 'הנחת קופון',
  orderNumber: 'מספר הזמנה: ',
  GroupsNav: 'קבוצות',
  GuestNav: 'מוזמנים',
  Messages: 'הודעות',
  Calls: 'שיחות',
  PaymentNav: 'תשלום',
  navTiming: 'תזמונים',
  ImportContacts: 'ייבוא מגוגל',
  CreateGroupsNav: 'צרו את הקבוצות הרלוונטיות עבור האירוע שלכם',
  SelectTimingNav: 'קבעו את שעות השליחה של אישורי ההגעה',
  AddGuestsNav: 'הוסיפו את המוזמנים לאירוע שלכם',
  EditMessagesNav: ' צפו וערכו את תבנית ההודעות לפי רצונכם',
  EditCallsNav: 'האזינו וערכו את תבנית השיחות לפי רצונכם',
  ChoosePackageNav: 'רכשו את החבילה המתאימה ביותר עבורכם',
  Attending: 'מגיעים',
  NotAttending: 'לא מגיעים',
  PlanChanged: 'התוכניות השתנו?',
  WeAreSorry: 'אנחנו ממש מתנצלים',
  WeUpdateWrongNumberInSystem: 'תודה על העדכון',
  YouChoseWhatsAppMessage: 'נבחרה חבילה הכוללת הודעות וואטסאפ',
  WhatsAppMessageWarning:
    'לידיעתכם, במידה ותהיה בעיה בשליחת הודעת WhatsApp למוזמן מסוים, מסיבה שאינה תלויה בנו, תשלח הודעת סמס במקום הודעת WhatsApp.',
  SortBy: 'מיון לפי',
  addToGroup: 'שיוך לקבוצה',
  countOfInvitesMustBeUntil10: 'המספר המקסימלי הוא 10',
  countOfInvitesBetween1to10: 'המספר חייב להיות בין 1 ל-10',
  ImportYourInviteeFromExcel: 'ייבוא מוזמנים מקובץ אקסל',
  ChooseForEachColumnFromExcel: 'בחרו לכול שדה את שם העמודה המתאימה לה מהקובץ שלכם',
  recognizeErrorsFromExcel: 'זיהינו בעיה בייבוא הרשומות הבאות',
  moreInfoAboutErrors: 'מידע נוסף על השגיאות לחצו על',
  fixTheExcelErrors: 'אנא תקנו את השגיאות וייבאו שוב את הקובץ',
  SuccessfullyAdded: ' נוספו בהצלחה',
  SuccessfullyAddedOneGuest: 'נוסף בהצלחה לטבלה',
  InvalidFullNameFormatColumn: 'עמודת שם המוזמן שנבחרה אינה תואמת לפורמט',
  isValidFormatPhoneColumn: 'עמודת המספר טלפון שנבחרה אינה תואמת לפורמט',
  isValidFormatCountOfInviteeColumn: 'עמודת מספר המוזמנים שנבחרה אינה תואמת לפורמט',
  ClearAll: 'נקה הכל',
  AllInviteeAlreadyExist: 'כלל המוזמנים מהקובץ כבר נוספו',
  InvalidFileType: 'קובץ לא חוקי, יש לטעון קובץ אקסל מסוג xlsx',
  InvalidFile: 'קובץ לא תקין',
  NeedHelp: 'צריכים עזרה? דברו איתנו!',
  StartRsvpDataInPast: 'תאריך תחילת אישורי הגעה לא יכול להיות בעבר',
  AddInvitees: 'הוסיפו מוזמנים',
  NotEnoughData: 'אין מספיק נתונים',
  AssignGroupsToInvitees: 'שייכו קבוצות למוזמנים',
  CreateGroups: 'צרו קבוצות',
  NoDataYet: 'אין עדיין נתונים',
  viewedInvitation: 'צפו בהזמנה',
  notViewedInvitation: 'לא צפו בהזמנה',
  FileIsEmpty: 'הקובץ אינו מכיל נתונים',
  PhoneNumberNotChanged: 'לא בוצע שינוי במספר הטלפון, על מנת להמשיך יש לשנות את מספר הטלפון',
  EditPhoneNumber: 'עריכת מספר טלפון',
  ChangedYourMind: 'משהו השתנה?',
  FileMustHaveTitles: 'הקובץ חייב לכלול כותרות',
  NavigationByWaze: 'ניווט באמצעות וויז',
  NavigationByGoogleMaps: 'ניווט באמצעות גוגל מפות',
  partiallyComing: 'מגיע חלקית',
  actionTiming: 'תזמונים',
  sameDay: 'ביום האירוע',
  dayBefore: 'יום לפני האירוע',
  twoDaysBefore: 'יומיים לפני האירוע',
  dayAfter: 'יום לאחר האירוע',
  twoDaysAfter: 'יומיים אחרי האירוע',
  daysBeforeEvent: 'ימים לפני האירוע',
  daysAfterEvent: 'ימים לאחר האירוע',
  startFrom: 'החל מ-',
  activityDays: ['ימי ראשון עד חמישי 09:00-20:30', 'ימי שישי 09:00-14:30'],
  selectActivityDays: 'ימי פעילות השירות שניתן לבחור הינם:',
  Agree: 'מאשר/ת',
  WhatsAppMessageTemplateExplain: 'לידיעתכם, ההודעה הראשונה שתשלח בוואטסאפ תהיה בנוסח: ',
  WhatsAppTemplate: `היי😊
הוזמנתם לאירוע {eventName}.
לקבלת ההזמנה בה תוכלו לאשר את הגעתכם - לחצו על הכפתור למטה "קבלת הזמנה" ⬇

*נשלח על ידי Anita lee, מערכת אישורי הגעה לאירועים.`,
  AfterWhatsAppButtonClick:
    'לאחר לחיצה על כפתור ״קבלת הזמנה״, המוזמן יקבל בהודעה חוזרת את ההודעה שמוגדרת בתבנית ההודעות(ניתנת לעריכה) עם לינק לדף ההזמנה של האירוע, בה ניתן לאשר הגעה.',
  edit_details_in_event_settings: 'ניתן לערוך את הפרטים בהגדרות האירוע',
  edit_messages_in_all_languages: 'ניתן לערוך את ההודעות בכל השפות שבחרתם בתבנית ההודעות',
  digital_invitation_is_ready: 'סידרנו לכם הזמנה דיגיטלית לאירוע',
  whenTimingWillSending: 'מתי מה ישלח?',
  round: 'סבב ',
  NoHaveClosestAction: 'האירוע הסתיים',
  closestAction: 'תזמון קרוב',
  upgrade: 'שדרוג',
  edit_details_in_message_template: 'ניתן לערוך את התוכן בתבנית ההודעות',
  how_messages_look_to_you: 'חשבנו על ניסוח ההודעות למוזמנים',
  we_set_when_each_round_will_sent: 'קבענו מתי ישלח כל סבב',
  edit_timing: 'ניתן לערוך את התזמונים בהמשך',
  starting_in_just_a_moment: 'עוד שנייה זה מתחיל...',
  we_prepare_everything_you_need: 'הכנו כמעט את כל מה שצריך,',
  lets_see: 'בואו לראות',
  AddManually: 'הוספה ידנית',
  we_do_almost_all_job: 'נשמע שעשינו כבר את כל העבודה',
  you_need_only_add_guests: '- לכם נשאר רק להוסיף מוזמנים',
  PleaseNote: 'שימו לב',
  TimingCanChange: 'תזמוני שליחת אישורי ההגעה עלולים להשתנות',
  TemplatesNeedManualEdit: 'יש לעדכן באופן ידני את פרטי השינוי בתבנית ההודעות/שיחות',
  DateOrTimeChanged: 'בוצע שינוי בתאריך ו/או בשעת האירוע',
  only_messages: 'רק הודעות',
  calls_with_messages: 'הודעות ושיחות',
  interests_package: 'החבילה שמעניינת אותי כוללת...',
  add_guests_later: 'הוספת מוזמנים במועד מאוחר יותר',
  Calling: 'מתקשר...',
  Accept: 'קבל/י',
  Decline: 'דחה/י',
  how_call_listening: 'חשבנו גם על איך ישמעו השיחות...',
  edit_call_in_all_languages: 'ניתן לערוך את פתיח השיחה בכל השפות שבחרתם בתבנית השיחות',
  edit_details_in_call_template: 'ניתן לערוך את פתיח השיחה בתבנית השיחות',
  invitation_page_loading_text: 'הזמנה מיוחדת בדרך אליך...',
}
