<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <div>
        <router-view></router-view>
      </div>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import ManageEventNav from '@/layouts/variants/manage-events/ManageEventNav.vue'

// Dynamic vh
import { i18n } from '@/plugins/i18n/index'
import useDynamicVh from '@core/utils/useDynamicVh'
import { provideToast } from 'vue-toastification/composition'
import 'vue-toastification/dist/index.css'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
    ManageEventNav,
  },
  setup() {
    provideToast({ rtl: i18n.locale === 'ar' || i18n.locale === 'he' })
    const { route } = useRouter()
    // eslint-disable-next-line object-curly-newline
    const { appContentLayoutNav, appSkinVariant, appRouteTransition, isRtl } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'manage-events') return 'manage-event-nav'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    watch(
      () => i18n.locale,
      newLocale => {
        isRtl.value = newLocale === 'ar' || newLocale === 'he'
      },
      { immediate: true },
    )

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
